<div class="w-full sidebar">
  <efp-safe-area-top [backgroundColor]="topSafeAreaBackgroundColor"></efp-safe-area-top>
  <div class="flex flex-col justify-between flex-1">
    <ul class="sidebar-items">
      <li
        class="sidebar-item border-solid border-b-[1px] border-primary border-light pb-2 mb-4 flex justify-between align-center">
        <div class="pl-6 pr-12 title">Easy Formwork Planner</div>
        <button
          class="action-icon"
          *ngIf="collapsable"
          (click)="closeMenu()"
          data-test-selector="primary-menu-close-btn">
          close
        </button>
      </li>
      <li
        class="sidebar-item"
        routerLinkActive="sidebar-item-selected"
        data-test-selector="primary-menu-homepage-btn">
        <a routerLink="/homepage" class="w-full pl-6 pr-2 sidebar-item-link" (click)="closeMenu()">
          <span class="sidebar-item-link-icon">home</span>
          <span class="sidebar-item-link-text">{{ 'HOME.TITLE' | translate }}</span>
        </a>
      </li>

      <li
        class="sidebar-item"
        routerLinkActive="sidebar-item-selected"
        data-test-selector="primary-menu-project-overview-btn">
        <a
          class="w-full pl-6 pr-2 sidebar-item-link"
          routerLink="/projectoverview"
          (click)="closeMenu()">
          <span class="sidebar-item-link-icon">folder_open</span>
          <span class="sidebar-item-link-text">{{ 'HOME.PROJECTOVERVIEW' | translate }}</span>
        </a>
      </li>

      <li
        class="sidebar-item"
        routerLinkActive="sidebar-item-selected"
        data-test-selector="primary-menu-stock-btn">
        <a class="w-full pl-6 pr-2 sidebar-item-link" routerLink="/stock" (click)="closeMenu()">
          <span class="sidebar-item-link-icon">garage_home</span>
          <span class="sidebar-item-link-text">{{ 'HOME.STOCK' | translate }}</span>
        </a>
      </li>
      <li
        class="sidebar-item"
        routerLinkActive="sidebar-item-selected"
        data-test-selector="primary-menu-favourites-overview-btn">
        <a
          routerLink="/favourites-overview"
          class="w-full pl-6 pr-2 sidebar-item-link"
          (click)="closeMenu()">
          <span class="sidebar-item-link-icon">folder_special</span>
          <span class="sidebar-item-link-text">{{
            'SETTINGS.FAVOURITES_OVERVIEW' | translate
          }}</span>
        </a>
      </li>
      <li
        class="sidebar-item"
        routerLinkActive="sidebar-item-selected"
        data-test-selector="primary-menu-settings-btn">
        <a class="w-full pl-6 pr-2 sidebar-item-link" routerLink="/settings" (click)="closeMenu()">
          <span class="sidebar-item-link-icon">settings</span>
          <span class="sidebar-item-link-text">{{ 'HOME.SETTINGS' | translate }}</span>
        </a>
      </li>

      <li class="sidebar-item">
        <a
          data-test-selector="primary-menu-start-feedback-btn"
          routerLink="."
          class="w-full pl-6 pr-2 sidebar-item-link"
          data-test-selector="homepage-feedback"
          (click)="startFeedback()">
          <span class="sidebar-item-link-icon">feedback</span>
          <span class="sidebar-item-link-text"> {{ 'HOME.FEEDBACK' | translate }}</span>
        </a>
      </li>
    </ul>

    <div class="secondary-menu-wrapper">
      <div class="inner-wrapper d-flex-col">
        <a
          *ngIf="privacyPolicy"
          class="secondary-nav-item"
          data-test-selector="more-link-agb"
          target="_blank"
          href="{{ privacyPolicy.gdprUrl }}">
          <span>{{ 'LEGAL_MODAL.SITE_2.GDRP' | translate }}</span>
        </a>
        <a
          *ngIf="privacyPolicy"
          class="secondary-nav-item"
          data-test-selector="more-link-privacy"
          target="_blank"
          href="{{ privacyPolicy.dtdsUrl }}">
          <span> {{ 'LEGAL_MODAL.SITE_2.DTS' | translate }}</span>
        </a>
        <a
          class="secondary-nav-item"
          data-test-selector="more-link-licenses"
          (click)="closeMenu()"
          routerLink="/licenses"
          routerLinkActive="sidebar-item-selected">
          <span> {{ 'LICENSES.NAV_TITLE' | translate }} </span>
        </a>
        <a class="secondary-nav-item" (click)="contact()" data-test-selector="more-link-contact">
          <span>{{ 'CONTACT.NAV_TITLE' | translate }}</span>
        </a>
        <a
          (click)="openUserInformation()"
          class="secondary-nav-item"
          routerLinkActive="sidebar-item-selected"
          data-test-selector="more-link-user-information">
          <span>{{ 'USER_INFORMATION.NAV_TITLE' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
<efp-safe-area-bottom></efp-safe-area-bottom>
