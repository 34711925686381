<ion-list class="d-flex-col">
  <efp-list-header>
    <ion-label>
      {{ 'ACC.ACCOUNTS' | translate }}
    </ion-label>
  </efp-list-header>

  <div
    class="flex-1 gap-3 pb-6 d-flex-col"
    *ngIf="{ enabled: this.accConnectorEnabled$ | async } as obj">
    <div
      class="d-flex form-container d-flex-responsive-wrap d-flex-responsive-gap"
      *ngIf="obj.enabled === false">
      <p class="header-description">
        <ion-note>
          {{ 'ACC.ACCOUNT_LINK_INFO' | translate }}
        </ion-note>
      </p>
    </div>

    <div class="d-flex form-container d-flex-responsive-wrap d-flex-responsive-gap">
      <div class="flex-1 label-container d-flex">
        <ion-label>Autodesk Construction Cloud</ion-label>
      </div>
      <div *ngIf="!getAccUserMail(); else connectedAcc">
        <button
          (click)="connectToAcc()"
          class="flex-1 button button-secondary d-flex cta-btn"
          fill="outline">
          <span class="">
            {{ 'ACC.CONNECT' | translate }}
          </span>
          <ion-icon src="assets/icon/arrow-forward-outline.svg" slot="end"></ion-icon>
        </button>
      </div>
      <ng-template #connectedAcc>
        <div class="flex-1 d-flex account-container">
          <div class="gap-2 d-flex inner">
            <ion-badge
              [ngClass]="{ success: obj.enabled }"
              efpTooltip
              [tooltipTitle]="getAuthText(obj.enabled)"
              [placement]="tooltipPosition.TOP">
              <ion-icon *ngIf="obj.enabled; else connectorNotEnabled" name="lock-open-outline">
              </ion-icon>
              <ng-template #connectorNotEnabled>
                <ion-icon *ngIf="obj.enabled === false" name="lock-closed-outline"> </ion-icon>
              </ng-template>
            </ion-badge>
            <span class="mail">{{ getAccUserMail() }}</span>
          </div>

          <button
            class="action-icon button-danger remove-account bg-background-danger enabled:hover:bg-background-danger-hover bg-background-danger-focus disabled:bg-background-danger"
            (click)="unlinkAlert()"
            color="danger">
            <ion-icon name="remove-circle-outline" size="small"></ion-icon>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</ion-list>
