import { NgModule } from '@angular/core'
import { SimpleModalWrapperComponent } from './simple-modal-wrapper/simple-modal-wrapper.component'
import { TranslateModule } from '@ngx-translate/core'
import { CommonModule } from '@angular/common'
import { IonicModule } from '@ionic/angular'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'
import { FormworkPlannerModule } from '../../formwork-planner'
import { RouterModule } from '@angular/router'
import { NgxCaptureModule } from 'ngx-capture'
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component'
import { SharedModule } from '../../shared.module'

@NgModule({
  declarations: [SimpleModalWrapperComponent, DynamicFormComponent],
  imports: [
    TranslateModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerModule,
    FormworkPlannerModule,
    RouterModule,
    NgxCaptureModule,
    SharedModule,
  ],
  exports: [SimpleModalWrapperComponent, DynamicFormComponent],
})
export class SimpleModalModule {}
