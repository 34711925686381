<ion-app>
  <router-outlet id="main-content"></router-outlet>

  <!-- This is to preload the modal component -->
  <ion-modal style="display: none"></ion-modal>

  <efp-simple-modal-wrapper
    [isOpen]="isAppVersionError || isSynchronizationError || isInvalidCacheError"
    [dismissable]="getModalDismissable()">
    <doka-modal
      [title]="getModalTitle()"
      [text]="getModalText()"
      [confirmButtonLabel]="getModalConfirmBtnText()"
      [confirmButtonDisabled]="isSynchronizationError && !hasNetworkConnection"
      [modalStyle]="getModalStyle()"
      (dismissed)="closeErrorModal()"
      [dismissable]="getModalDismissable()"
      (confirm)="getModalConfirmFunction()">
    </doka-modal>
  </efp-simple-modal-wrapper>
</ion-app>
