import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './shared/guards/auth.guard'
import { WidgetGuard } from './shared/guards/widget.guard'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'homepage',
    loadChildren: async () => (await import('./pages/homepage/homepage.module')).HomepagePageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'projectoverview',
    loadChildren: async () =>
      (await import('./pages/projectoverview/projectoverview.module')).ProjectoverviewPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'projectdetail',
    loadChildren: async () =>
      (await import('./pages/projectoverview/projectdetail/projectdetail.module'))
        .ProjectdetailPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'favourites',
    loadChildren: async () =>
      (await import('./pages/favourites/favourites.module')).FavouritesPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'favourites-overview',
    loadChildren: async () =>
      (await import('./pages/favourites/favourites-overview/favourites-overview.module'))
        .FavouritesOverviewModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'stock',
    loadChildren: async () => (await import('./pages/stock/stock.module')).StockPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'stockdetail',
    loadChildren: async () =>
      (await import('./pages/stock/stockdetail/stockdetail.module')).StockdetailPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'settings',
    loadChildren: async () =>
      (await import('./pages/settings/appsettings/appsettings.module')).SettingsPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'drawingsettings',
    loadChildren: async () =>
      (await import('./pages/settings/drawingsettings/drawingsettings.module'))
        .DrawingsettingsPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'contact',
    loadChildren: async () => (await import('./pages/contact/contact.module')).ContactPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'privacy',
    loadChildren: async () => (await import('./pages/privacy/privacy.module')).PrivacyPageModule,
  },
  {
    path: 'introduction',
    loadChildren: async () =>
      (await import('./pages/introduction/introduction.module')).IntroductionPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'planner',
    loadChildren: async () => (await import('./pages/planner/planner.module')).PlannerPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'accessory',
    loadChildren: async () =>
      (await import('./pages/accessory/accessory.module')).AccessoryPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'cycles',
    loadChildren: async () => (await import('./pages/cycles/cycles.module')).CyclesPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'result',
    loadChildren: async () => (await import('./pages/result/result.module')).ResultPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'plansettings',
    loadChildren: async () =>
      (await import('./pages/settings/plansettings/plansettings.module')).PlansettingsPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'licenses',
    loadChildren: async () => (await import('./pages/licenses/licenses.module')).LicensesPageModule,
    canActivate: [AuthGuard, WidgetGuard],
  },
  {
    path: 'login',
    loadChildren: async () => (await import('./pages/login/login.module')).LoginPageModule,
  },
  {
    path: 'widget',
    loadChildren: async () => (await import('./pages/widget/widget.module')).WidgetPageModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-plan',
    loadChildren: async () =>
      (await import('./pages/create-plan/create-plan.module')).CreatePlanPageModule,
    canActivate: [AuthGuard],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
